<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 申请退款
 * @Date: 2021-01-08 13:52:18
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-08 14:50:58
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/refund.vue
-->
<template>
  <div class="user_content">
    <div class="info">
      <div class="info_img" />
      <div class="info_test">
        <p>梦妆山茶臻颜面霜紧致抗皱抗衰老冬天保湿...</p>
        <p>SKU商品属性</p>
        <p><span>¥249.00</span><span>×2</span></p>
      </div>
    </div>
    <div class="reason">
      <div @click="isreason">
        <span>退款原因</span><span>{{ reason }} <van-icon name="arrow" /></span>
      </div>
      <div>
        <span>退款数量</span><span><van-stepper v-model="value" /></span>
      </div>
    </div>
    <div class="explain">
      <div>退款金额：<span>¥580.00</span></div>
      <div>可修改，最多¥580.00，含发货邮费¥25.00</div>
      <div>
        <van-field
          v-model="message"
          rows="1"
          autosize
          label="退货说明："
          type="textarea"
          placeholder="选填"
          label-align="left"
        />
      </div>
    </div>
    <div class="ref_btn">
      <div>申请退款</div>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Field, Icon, Picker, Popup, Stepper } from 'vant'

Vue.use(Icon).use(Stepper).use(Field).use(Picker).use(Popup)
export default {
  data() {
    return {
      reason: '请选择',
      value: 1,
      message: '',
      show: false,
      columns: ['商品质量问题', '物流太慢', '7天内退货', '不想要了', '其他']
    }
  },
  methods: {
    isreason() {
      this.show = true
    },
    onConfirm(value, index) {
      this.reason = value
      this.show = false
    },
    onCancel() {
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .info {
    background: #FFFFFF;
    padding: 13px;
    display: flex;

    .info_img {
      width: 90px;
      height: 90px;
      background: #D6D6D6;
    }

    .info_test {
      margin-left: 10px;
      width: 100%;
      text-align: left;

      & > p:nth-child(2) {
        margin-top: 10px;
        color: #999999;
        font-size: 12px;
      }

      & > p:nth-child(3) {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        & > span:nth-child(1) {
          color: #333333;
        }

        & > span:nth-child(2) {
          color: #999999;
        }
      }
    }
  }

  .reason {
    margin-top: 10px;
    padding: 13px;
    background: #FFFFFF;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;

      & > span:nth-child(1) {
        color: #141414;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .explain {
    margin-top: 10px;
    padding: 13px;
    background: #FFFFFF;
    text-align: left;

    & > div:nth-child(1) {
      font-weight: 500;
      color: #141414;

      & > span {
        color: #BB3627;
      }
    }

    & > div:nth-child(2) {
      margin-top: 10px;
      color: #999999;
    }

    & > div:nth-child(3) {
      margin-top: 10px;
      color: #999999;

      .van-field {
        padding: 0;
      }
    }
  }

  .ref_btn {
    margin-top: 50px;
    padding: 13px;

    & > div {
      background: #E33F44;
      padding: 14px;
      color: #fff;
      border-radius: 20px;
    }
  }
}

/deep/ .van-field__control {
  padding: 5px;
  background: #F6F6F6;
}
</style>
